import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

const ProgramThree = () => {
  return (
    <div className="section-padding case-study-featured-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <h3>Project WAU</h3>
                <p className="fs-5">
                  Project WAU (War Against Unemployment) is a movement of concerned voices geared towards social mindfulness on the issues of unemployment. Through public campaigns, petitoning of various establishments, training and mentorship, we are strategically influencing decisions on unemployment and propelling job creations at all levels.
                </p>
                <Button variant="outline-success">Read More</Button>
                {/* <Link to="#" className="axil-btn btn-large btn-fill-primary">
                    Our Team
                  </Link> */}
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured">
              <div className="thumbnail">
                <img
                className="w-100"
                  src={process.env.PUBLIC_URL + "/images/wau.jpg"}
                  alt="thumbnail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramThree;
