import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import FormTwo from '../component/contact/FormTwo';
import SectionTitle from '../elements/section-title/SectionTitle';
import ContactLocation from '../component/contact/ContactLocation';
import { FaAngleRight } from "react-icons/fa";
import {FaNewspaper}  from "react-icons/fa"
import {FaHandshakeAltSlash} from "react-icons/fa"



const Contact = () => {
    return (
      <>
        <SEO title="Contact" />
        <ColorSwitcher />
        <main className="main-wrapper">
          <HeaderOne />
          <BreadCrumbOne title="Contact" page="Contact" />

          <div className="section section-padding">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-6">
                  <div className="contact-form-box shadow-box mb--30">
                    <h3 className="title">We'd love to hear from you</h3>
                    <FormTwo />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 ">
                  <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--10">
                    <h4 className="title">Phone</h4>
                    <p>
                      Our customer care is open from Mon-Fri, 10:00 am to 6:00
                      pm
                    </p>
                    <h4 className="phone-number">
                      <a href="tel:1234567890">(+1) 951 446 9482</a>
                    </h4>
                    <h4 className="phone-number">
                      <a href="tel:1234567890">(+234) 915 555 6090</a>
                    </h4>
                  </div>
                  <div className="contact-info mb--30">
                    <h4 className="title">Email</h4>
                    <p>
                      Our support team will get back to you in 48-h during standard
                      business hours.
                    </p>
                    <h4 className="phone-number">
                      <a href="mailto:info@example.com">info@emplug.com</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <ul className="list-unstyled shape-group-12">
              <li className="shape shape-1">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                  alt="Bubble"
                />
              </li>
              <li className="shape shape-2">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                  alt="Bubble"
                />
              </li>
              <li className="shape shape-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                  alt="Circle"
                />
              </li>
            </ul>
          </div>

          <div
            className="section section-padding bg-color-light spalsh-why-choose"
            id="splash-why-choose"
          >
            <div className="container">
              <div className="section-heading heading-left">
                <div className="row align-items-center">
                  <div className="col-xl-6 col-lg-7">
                    <h2 className="title">We'd love to hear from you</h2>
                  </div>
                  <div className="col-xl-4 col-lg-5 offset-xl-2">
                    <p>
                      Whether you’re curious about features, a free trial, or
                      even press—we’re ready to answer any and all questions.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                {FeatureData.slice(9, 12).map((data) => (
                  <div className="col-xl-4 col-lg-6" key={data.id}>
                    <div className="why-buy-box">
                      <div className="heading">
                        <div className="icon">
                          <img
                            src={process.env.PUBLIC_URL + data.icon}
                            alt="Thumb"
                          />
                        </div>
                        <h5
                          className="title"
                          dangerouslySetInnerHTML={{ __html: data.title }}
                        ></h5>
                      </div>
                      <p>{data.para}</p>
                    </div>
                  </div>
                ))}
              </div> */}

              <div className="row ">
                <div className="col-xl-4 col-lg-6">
                  <div className="support-box online-docuentation splash-hover-control">
                    <div>
                      <div className="inner">
                        <div className="content">
                          <div className="heading">
                            <h4 className="title">Media</h4>
                            <div className="icon">
                              <FaNewspaper size={30} />
                            </div>
                          </div>
                          <p>
                            Get in touch with us for media updates or articles
                          </p>
                        </div>
                        <div className="btn-area">
                          <span className="item-btn">
                            <FaAngleRight />
                          </span>
                        </div>
                      </div>
                      <ul className="shape-group list-unstyled">
                        <li className="shape-1">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/others/bubble-34.png"
                            }
                            alt="Shape"
                          />
                        </li>
                        <li className="shape-2">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/others/line-8.png"
                            }
                            alt="Shape"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6">
                  <div className="support-box support-ticket splash-hover-control">
                    <div>
                      <div className="inner">
                        <div className="content">
                          <div className="heading">
                            <h4 className="title">Support</h4>
                            <div className="icon">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/icon/icon-26.png"
                                }
                                alt="Thumb"
                              />
                            </div>
                          </div>
                          <p>
                            We response within 1 Business day. weekends
                            excluded.
                          </p>
                        </div>
                        <div className="btn-area">
                          <span className="item-btn">
                            <FaAngleRight />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6">
                  <div className="support-box support-ticket splash-hover-control">
                    <div>
                      <div className="inner">
                        <div className="content">
                          <div className="heading">
                            <h4 className="title">Partnership</h4>
                            <div className="icon">
                              <FaHandshakeAltSlash size={30} />
                            </div>
                          </div>
                          <p>Become a partner today!</p>
                        </div>
                        <div className="btn-area ">
                          <span className="item-btn">
                            <FaAngleRight />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-4 col-lg-6">
                  <div className="support-box support-ticket splash-hover-control">
                    <a href="https://support.axilthemes.com/support/">
                      <div className="inner">
                        <div className="content">
                          <div className="heading">
                            <h4 className="title">
                              Submit A <br /> Support Ticket
                            </h4>
                            <div className="icon">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/icon/icon-26.png"
                                }
                                alt="Thumb"
                              />
                            </div>
                          </div>
                          <p>
                            We response within 1 Business day. weekends
                            excluded.
                          </p>
                        </div>
                        <div className="btn-area">
                          <span className="item-btn">
                            <FaAngleRight />
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="line-shape">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-6.png"}
                alt="Line"
              />
            </div>
          </div>

          <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
        </main>
      </>
    );
}

export default Contact;