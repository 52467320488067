import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import { useState } from "react";
import { Link,useLocation  } from "react-router-dom";

const TermsOfUse = () => {
  const { pathname } = location;
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
   const [active4, setActive4] = useState(false);
  const activeClick = ()=>{
    setActive1(true)
    setActive2(false)
    setActive3(false)
    setActive4(false);
  }
  const activeClick2 = () => {
    setActive2(true);
    setActive1(false)
    setActive3(false)
    setActive4(false);
  };
  const activeClick3 = () => {
    setActive3(true);
    setActive1(false)
    setActive2(false)
    setActive4(false);
  };
    const activeClick4 = () => {
      setActive3(false);
      setActive1(false);
      setActive2(false);
      setActive4(true)
    };
  return (
    <>
      <SEO title="Terms & Policies" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Terms & Policies" page="Terms & Policies" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4">
                <div class="list-group mb-sm-2">
                  <Link
                    to="/terms&policies"
                    onClick={activeClick}
                    className={`
                        list-group-item list-group-item-action active &&  ${
                          pathname.includes("terms&policies") &&
                          "list-group-item list-group-item-action"
                        } `}
                  >
                    Terms of Service
                  </Link>
                  <Link
                    to="/privacy-policy"
                    onClick={activeClick2}
                    className={
                      active2
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                    }
                  >
                    Privacy Policies
                  </Link>
                  <Link
                    to="/cookie-policy"
                    onClick={activeClick3}
                    className={
                      active3
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                    }
                  >
                    Cookie Policies
                  </Link>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="privacy-policy-content">
                  <div className="section-title">
                    <h5 className="title">Terms of Service</h5>
                  </div>
                </div>
                <div className="privacy-policy-content">
                  <div id="terms">
                    <p>
                      By accessing this web site, you are agreeing to be bound
                      by these web site Terms of Service, all applicable laws
                      and regulations, and agree that you are responsible for
                      compliance with any applicable local laws. If you do not
                      agree with any of these terms, you are prohibited from
                      using or accessing this site. The materials contained in
                      this web site are protected by applicable copyright and
                      trade mark laws.
                    </p>
                    <hr />
                    <h5>USE LICENSE</h5>
                    <p>
                      Permission is granted to temporarily download one copy of
                      the materials (Information or software) on emplug.com's
                      web site for personal, Non-commercial transitory viewing
                      only. This is the grant of a license, not a transfer of
                      title, and under this license you may not modify or copy
                      the materials. Use the materials for any commercial
                      purpose, or for any public display (commercial or
                      non-commercial) attempt to decompile or reverse engineer
                      any software contained on emplug.com's web site. Remove
                      any copyright or other proprietary notations from the
                      materials; Transfer the materials to another person or
                      "mirror" the materials on any other server. This license
                      shall automatically terminate if you violate any of these
                      restrictions and may also be terminated by emplug.com at
                      any time. Upon terminating your viewing of these materials
                      or upon the termination of this license, you must destroy
                      any downloaded materials in your possession whether in
                      electronic or printed format
                    </p>
                    <hr />
                    <h5>DISCLAIMER</h5>
                    <p>
                      The materials on emplug.com's web site are provided "as
                      is". emplug.com makes no warranties, expressed or implied,
                      and hereby disclaims and negates all other warranties,
                      including without limitation, implied warranties or
                      conditions of merchantability, fitness for a particular
                      purpose, or non-infringement of intellectual property or
                      other violation of rights. Further, emplug.com does not
                      warrant or make any representations concerning the
                      accuracy, likely results, or reliability of the use of the
                      materials on its Internet web site or otherwise relating
                      to such materials or on any sites linked to this site.
                    </p>
                    <hr />
                    <h5>LIMITATIONS</h5>
                    <p>
                      In no event shall emplug.com or its suppliers be liable
                      for any damages (including, without limitation, damages
                      for loss of data or profit, or due to business
                      interruption) arising out of the use or inability to use
                      the materials on emplug.com's Internet site, even if
                      emplug.com or a emplug.com authorized representative has
                      been notified orally or in writing of the possibility of
                      such damage. Because some jurisdictions do not allow
                      limitations on implied warranties, or limitations of
                      liability for consequential or incidental damages, these
                      limitations may not apply to you.
                    </p>{" "}
                    <hr />
                    <h5>REVISION AND ERRATA</h5>
                    <p>
                      The materials appearing on emplug.com's web site could
                      include technical, typographical, or photographic errors.
                      emplug.com does not warrant that any of the materials on
                      its web site are accurate, complete, or current.
                      emplug.com may make changes to the materials contained on
                      its web site at any time without notice. emplug.com does
                      not, however, make any commitment to update the materials.
                    </p>
                    <hr />
                    <h5>LINKS</h5>
                    <p>
                      <span style={{ color: "black", fontWeight: "bold" }}>
                        emplug.com
                      </span>{" "}
                      has not reviewed all of the sites linked to its Internet
                      web site and is not responsible for the contents of any
                      such linked site. The inclusion of any link does not imply
                      endorsement by emplug.com of the site. Use of any such
                      linked web site is at the user's own risk.
                    </p>
                    <hr />
                    <h5>SITE TERMS OF USE MODIFICATION</h5>
                    <p>
                      <span style={{ color: "black", fontWeight: "bold" }}>
                        emplug.com
                      </span>{" "}
                      may revise these terms of use for its web site at any time
                      without notice. By using this web site you are agreeing to
                      be bound by the then current version of these Terms and
                      Conditions of Use
                    </p>
                    <hr />
                    <h5>GOVERNING LAW</h5>
                    <p>
                      Any claim relating to emplug.com's web site shall be
                      governed by the laws of the Federal Republic of Nigeria
                      without regard to its conflict of law provisions
                    </p>
                  </div>
                  <hr className="thicker" />

                  {/* <div id="gdpr">
                    <hr className="thicker" />
                    <h4>4. GDPR compliance</h4>
                    <p>
                      Sed nec ex vitae justo molestie maximus. Sed ut neque sit
                      amet libero rhoncus tempor. Fusce tempor quam libero,
                      varius congue magna tempus vitae. Donec a justo nec elit
                      sagittis sagittis eu a ante. Vivamus rutrum elit odio.
                      Donec gravida id ligula ut faucibus. Aenean convallis
                      ligula orci, ut congue nunc sodales ut. In ultrices elit
                      malesuada velit ornare, eget dictum velit hendrerit.
                      Praesent bibendum blandit lectus, eu congue neque mollis
                      in. Pellentesque metus diam, hendrerit in purus fringilla,
                      accumsan bibendum sapien. Nunc non facilisis sem.
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <CtaLayoutOne /> */}
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default TermsOfUse;
