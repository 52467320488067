import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import BlogOne from '../component/blog/BlogOne';
import BrandOne from '../component/brand/BrandOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import TestimonialOne from '../component/testimonial/TestimonialOne';
import ProjectOne from '../component/project/ProjectOne';
import ServicePropOne from '../component/service/ServicePropOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import BannerFive from '../component/banner/BannerFive';
import HeaderFour from '../common/header/HeaderFour';
import ProgramOne from '../component/programs/ProgramOne';
import ProgramTwo from '../component/programs/ProgramTwo';
import ProgramThree from '../component/programs/ProgramThree';
import ProgramFour from '../component/programs/ProgramsFour';
import ProgramFive from '../component/programs/ProgramFive';
import ProgramSix from '../component/programs/ProgramSix';
import ProgramSeven from '../component/programs/ProgramSeven';
import ProgramEight from '../component/programs/ProgramEight';
import ProgramNine from '../component/programs/ProgramNine';
import ProgramTen from '../component/programs/ProgramTen';

const Program = () => {

    return (
        <>
        <SEO title="Corporate Agency"/>
        <ColorSwitcher />
        <main className="main-wrapper">
        <HeaderFour />
        <BannerFive />

       <ProgramOne/>
       <ProgramTwo/>
       <ProgramThree/>
       <ProgramFour/>
       <ProgramFive/>
       <ProgramSix/>
       <ProgramSeven/>
       <ProgramEight/>
       <ProgramNine/>
       <ProgramTen/>
        {/* <ProjectOne parentClass="bg-color-light" />
        <TestimonialOne /> */}
        {/* <BrandOne /> */}
        {/* <BlogOne /> */}
        {/* <CtaLayoutOne /> */}
        <FooterOne />
        </main>
        </>
    )
}

export default Program;