import React from 'react';
import { Link } from 'react-router-dom';
import FormOne from '../contact/FormOne';
import BcrumbBannerOne from '../../elements/breadcrumb/BcrumbBannerOne';


const BannerFive = () => {
    return (
      <div
        className="banner banner-style-5"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/images/banner/bannerx.jpg"
          })`,
        }}
      >
        <div className="container">
         
          <div className="row">
            <div className="col-lg-6 col-xl-7">
              <div className="banner-content">
                <h1 className="title">Sustainable Development</h1>
                <div>
                  <p className="text-white fs-5">
                    Given our belief enterprise and society that can be mutually
                    reinforcing. Emplug facilitates the implementation of social
                    causes in line with her vision, geared towards creating
                    shared value and sustainable home-grown solutions in
                    undeserved communities.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-5">
              {/* <div className="banner-form">
                            <div className="contact-form-box shadow-box">
                                <h3 className="title">Get a free Keystroke quote now</h3>
                                <FormOne />
                            </div>
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    );
}

export default BannerFive;