import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

const CareerFive = () => {
  return (
    <div className="section richard-section  case-study-featured-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <h3>Dependent Care</h3>
                <p>
                  We understand that employees have a range of responsibilities
                  outside of the workplace. Our employee assistance programs,
                  therefore, take an inclusive approach in defining an
                  employee's "dependent" as someone who relies on the employee
                  for financial or emotional well-being, even if this person is
                  not a member of the employee's immediate family.
                </p>
                {/* Emplug Inc. invests in services and education to keep our
                employees healthy. All Emplug Inc. businesses offer access to
                employee assistance and mental health programs. Our offerings
                include programs for pregnant women, cash reimbursement for
                wellness activities, onsite fitness centers and onsite health
                services. We also provide information to our employees about
                health and wellness through seminars and awareness campaigns. */}
                {/* <Link to="#" className="axil-btn btn-large btn-fill-primary">
                    Our Team
                  </Link> */}
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="thumbnail">
                <img
                  src={process.env.PUBLIC_URL + "/images/care.jpg"}
                  alt="thumbnail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerFive;
