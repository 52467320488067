import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';
import Mailgun from 'mailgun.js'

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
        Your Message has been successfully sent.
        </Alert>
    )
}

const FormTwo = () => {

    const mailgun = new Mailgun(FormData);
 const mg = mailgun.client({
   username: "contact@emplug.com",
   key:
     process.env.MAILGUN_API_KEY ||
     "59ae0ea48983a1dda29acf468807aa64-4dd50799-7bdec700",
 });

 const [result, showresult] = useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [category, setCategory] = React.useState("");


  const handleSubmit = (e) => {
    e.preventDefault();
    
    const data = {
      from: email,
      to: "contact@emplug.com",
      subject: `Enquiry from ${name} on ${category}`,
      text: message,
    };  
    mg.messages
      .create("emplug.com.ng", data)
      .then((msg) => {
        showresult(true);
        
        console.log(msg);
        
      })
      .catch((err) => console.log(err));
      setName("")
      setEmail("")
      setMessage("")
      setCategory("");
     
  }

        setTimeout(() => {
            showresult(false);
        }, 7000);


    return (
      <form  onSubmit={handleSubmit} className="axil-contact-form">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            name="contact-name"
            required
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            name="contact-email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        {/* <div className="form-group mb-40">
          <label>Phone</label>
          <input
            type="tel"
            className="form-control"
            name="contact-phone"
            required
          />
        </div> */}
        <div className="form-group mb--40">
          {/* <label>How can we help you?</label> */}
          <select
            className="form-select form-select-lg form-control"
            aria-label="Default select example"
            onChange={(e) => setCategory(e.target.value)}
          >
            <option selected>Select a Category</option>
            <option value="Investment">Investments</option>
            <option value="PlugBay">PlugBay</option>
            <option value="The Emplug Ecosystem">The Emplug Ecosystem</option>
            <option value="Becoming a Member">Becoming a Member</option>
            <option value="Enquiry">Enquiry</option>
          </select>
        </div>
        <div className="form-group mb--40">
          <label>How can we help you?</label>
          <textarea
            className="form-control"
            name="contact-message"
            rows="4"
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>

        <div className="form-group">
          <button
            type="submit"
            disabled={result}
            className="axil-btn btn-fill-primary btn-fluid btn-primary"
            name="submit-btn"
          >
            Get Connected Now!
          </button>
        </div>
        <div className="form-group">{result ? <Result /> : null}</div>
      </form>
    );
}

export default FormTwo;