import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

const ProgramFive = () => {
  return (
    <div className="section-padding case-study-featured-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <h3>Career Clinic</h3>
                <p className="fs-5">
                  Welcome to the Emplug Career Clinic, an unconventional 21st
                  Century one stop career solution experience for job seekers,
                  young professionals and employers. Reflecting both local and
                  global needs of the evolving workplace simultaneously, the
                  Emplug Career clinic brings into perspective not only
                  international best practices but tailored to local market
                  needs
                </p>
                <Button variant="outline-success">Read More</Button>
                {/* <Link to="#" className="axil-btn btn-large btn-fill-primary">
                    Our Team
                  </Link> */}
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured">
              <div className="thumbnail">
                <img
                  className="w-100"
                  src={process.env.PUBLIC_URL + "/images/careerx.jpg"}
                  alt="thumbnail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramFive;
