import React from "react";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaBehance,
  FaPhone,
  FaFax,
} from "react-icons/fa";

const OffcanvasMenu = ({ offcanvasShow, offcanvasHide }) => {
  return (
    <Offcanvas
      show={offcanvasShow}
      onHide={offcanvasHide}
      placement="end"
      className="header-offcanvasmenu "
    >
      <Offcanvas.Header>
        <h4 style={{ color: "#017F81" }}>Our Ecosystem</h4>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="container overflow-hidden">
          {" "}
          <div className="row ">
            <div className="col-4 col-md-4 ">
              {" "}
              <a href="www.plugbay.com">
                <img
                  style={{ width: "100px" }}
                  className="mr-sm-4 "
                  src={process.env.PUBLIC_URL + "/images/iconx.png"}
                  alt="Comments"
                />
              </a>
            </div>
            <div className="col-4 col-md-4 ">
              {" "}
              <Link to="/coming-soon">
              <img
                style={{
                  width: "35px",
                  marginLeft: "25px",
                  paddingTop: "15px",
                }}
                className=""
                src={process.env.PUBLIC_URL + "/images/plug2jobsicon.png"}
                alt="Comments"
              />
              </Link>
            </div>

            <div className="col-4 col-md-4">
              {" "}
              <img
                style={{ width: "50px" }}
                className=" mt-4 mt-md-4"
                src={process.env.PUBLIC_URL + "/images/careerclinic.png"}
                alt="Comments"
              />
            </div>
          </div>
          <div className="row mx-3 ">
            <div className="col-4 col-md-4 ">
              {" "}
              <img
                style={{ width: "50px" }}
                className=""
                src={process.env.PUBLIC_URL + "/images/plug2adicon.png"}
                alt="Comments"
              />
            </div>
            <div className="col-4 col-md-4">
              {" "}
              <img
                style={{}}
                className="emchat "
                src={process.env.PUBLIC_URL + "/images/emchat.png"}
                alt="Comments"
              />
            </div>

            <div className="col-4 col-md-4 ">
              {" "}
              <img
                style={{
                  width: "40px",
                }}
                className="academy "
                src={process.env.PUBLIC_URL + "/images/emplugacademyicon.png"}
                alt="Comments"
              />
            </div>
          </div>
          <div className="row mx-2 mx-md-3 gx-5 mt-2 mb-2 ">
            <div className="col-4 col-md-4 ">
              {" "}
              <img
                style={{ width: "40px" }}
                className=""
                src={process.env.PUBLIC_URL + "/images/taskam.png"}
                alt="Comments"
              />
            </div>
            <div className="col-4 col-md-4  ml-4">
              {" "}
              <img
                style={{ width: "30px", marginLeft: "20px" }}
                className="ml-6"
                src={process.env.PUBLIC_URL + "/images/reportamicon.png"}
                alt="Comments"
              />
            </div>

            <div className="col-4 col-md-4 ml-2">
              {" "}
              <img
                style={{ width: "50px", paddingLeft: "10px" }}
                className=""
                src={process.env.PUBLIC_URL + "/images/codahub2.png"}
                alt="Comments"
              />
            </div>
          </div>
          <div className="row mx-md-3 mx-2 mt-4">
            <div className="col-4 col-md-4 ">
              {" "}
              <img
                style={{ width: "35px" }}
                className=""
                src={process.env.PUBLIC_URL + "/images/plugamicon.png"}
                alt="Comments"
              />
            </div>

            <div className="col-4 col-md-4">
              {" "}
              <img
                style={{}}
                className="farmless"
                src={process.env.PUBLIC_URL + "/images/farmless.png"}
                alt="Comments"
              />
            </div>
            <div className="col-4 col-md-4 ">
              {" "}
              <img
                style={{ width: "60px", paddingLeft: "10px" }}
                className=""
                src={process.env.PUBLIC_URL + "/images/plugexicon.png"}
                alt="Comments"
              />
            </div>
          </div>
        </div>

        {/* <div className="row ">
             <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                            <li><Link to={process.env.PUBLIC_URL + "/digital-agency"}>Digital Agency</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/creative-agency"}>Creative Agency</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/personal-portfolio"}>Personal Portfolio</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/home-startup"}>Home Startup</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/corporate-agency"}>Corporate Agency</Link></li>
                        </ul>
                    </div> 
            <div className="col-lg-7 col-xl-6">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                <address className="address">
                                    <span className="title">Contact Information</span>
                                    <p>Theodore Lowe, Ap #867-859 <br /> Sit Rd, Azusa New York</p>
                                </address>
                                <address className="address">
                                    <span className="title">We're Available 24/7. Call Now.</span>
                                    <a href="tel:8884562790" className="tel"><FaPhone /> (888) 456-2790</a>
                                    <a href="tel:12125553333" className="tel"><FaFax /> (121) 255-53333</a>
                                </address>
                            </div>
                            <div className="contact-inner">
                                <h5 className="title">Find us here</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                        <li>
                                            <a href="https://facebook.com/"><FaFacebookF /></a>
                                        </li>

                                        <li>
                                            <a href="https://twitter.com/"><FaTwitter /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.behance.net/"><FaBehance /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/"><FaLinkedinIn /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> 
          </div> */}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasMenu;
