import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import Button from "react-bootstrap/Button";

const CareerTwo = () => {
  return (
    <div className="section richard-section case-study-featured-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-6">
            <div className="case-study-featured">
              <img
                src={process.env.PUBLIC_URL + "/images/internx.jpg"}
                alt="travel"
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left ">
                <h3 className="title">Internship</h3>
                <p>
                  Looking for student events, internships, thesis work, or other
                  student opportunities?
                </p>
                <Button variant="dark">Apply Now</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerTwo;
