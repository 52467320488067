import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

const CareerFour = () => {
  return (
    <div className="section richard-section  case-study-featured-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <h3>Flexible Work Arrangements</h3>
                <p>
                  At some of our divisions, employees partner with their
                  managers to develop flexible work arrangement schedules. The
                  purpose of these schedules is to enable employees increased
                  flexibility in how, when, and where they get their work done
                  and to increase employee satisfaction and decrease unwanted
                  turnover
                </p>
                {/* We understand that employees have a range of responsibilities
                outside of the workplace. Our employee assistance programs,
                therefore, take an inclusive approach in defining an employee's
                "dependent" as someone who relies on the employee for financial
                or emotional well-being, even if this person is not a member of
                the employee's immediate family. */}
                {/* <Link to="#" className="axil-btn btn-large btn-fill-primary">
                    Our Team
                  </Link> */}
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="thumbnail">
                <img
                  src={process.env.PUBLIC_URL + "/images/flexible.jpg"}
                  alt="thumbnail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerFour;
