import React from "react";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import FooterOne from "../common/footer/FooterOne";
import BlogOne from "../component/blog/BlogOne";
import BrandOne from "../component/brand/BrandOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import TestimonialOne from "../component/testimonial/TestimonialOne";
import ProjectOne from "../component/project/ProjectOne";
import ServicePropOne from "../component/service/ServicePropOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import BannerTwo from "../component/banner/BannerTwo";
import HeaderFour from "../common/header/HeaderFour";
import ProgramOne from "../component/programs/ProgramOne";
import ProgramTwo from "../component/programs/ProgramTwo";
import ProgramThree from "../component/programs/ProgramThree";
import ProgramFour from "../component/programs/ProgramsFour";
import ProgramFive from "../component/programs/ProgramFive";
import ProgramSix from "../component/programs/ProgramSix";
import ProgramSeven from "../component/programs/ProgramSeven";
import CareerOne from "../component/career/CareerOne";
import CareerTwo from "../component/career/CareerTwo";
import CareerThree from "../component/career/CareerThree";
import CareerFour from "../component/career/CareerFour";
import VideoOne from "../component/video/VideoOne";
import { Swiper, SwiperSlide } from "swiper/react";
import TestimonialPropTwo from "../component/testimonial/TestimonialPropTwo";
import TestimonialData from "../data/testimonial/TestimonialData.json";
import { Button } from "react-bootstrap";
import { slugify } from "../utils";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";
import ProgramNine from "../component/programs/ProgramNine";
import CareerFive from "../component/career/CareerFive";
import CareerSix from "../component/career/CareerSix";


const allData = TestimonialData;
const Career = () => {
  const facebookData = allData.filter(
    (data) => slugify(data.fromtext) === "facebook"
  );
  const googleData = allData.filter(
    (data) => slugify(data.fromtext) === "google"
  );
  const yelpData = allData.filter((data) => slugify(data.fromtext) === "yelp");

  return (
    <>
      <SEO title="Corporate Agency" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderFour />
        <BannerTwo />
        <div className="career-section">
          <SectionTitle
            subtitle=""
            title="The Emplug Culture"
            description="What we practice on the outside begins from the inside with staff benefiting from all the opportunities we profess and in a most amicable partnership style of working. This means that Emplug is family and made up of people with like minds and goals and this is the culture we carry throughout the entire organization’s structure making every individual feel important in their roles no matter where you belong in the organization."
            textAlignment=""
            textColor="mb--90"
          />
        </div>

        <div style={{ backgroundColor: " #C3C7C7" }} className="pt-4">
          <SectionTitle
            subtitle=""
            title="Benefits at Emplug "
            description=""
            textAlignment=""
            textColor="mb--20"
          />

          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 8000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <CareerThree />
            </SwiperSlide>
            <SwiperSlide>
              <CareerFour />
            </SwiperSlide>
            <SwiperSlide>
              <CareerFive />
            </SwiperSlide>
            <SwiperSlide>
              <CareerSix />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="section section-padding customer-review-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <SectionTitle
                  subtitle=""
                  title="Jobs at Emplug"
                  description="Here at Emplug, we are always looking to work with brilliant minds and individuals willing to make an impact in the society. "
                  textAlignment="heading-left"
                  textColor=""
                />
                <div className="content">
                  <Button variant="dark">Proceed to Apply</Button>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="review-site-logo">
                  <a href="https://www.plug2jobs.com/">
                    <img
                      src={process.env.PUBLIC_URL + "/images/plug2jobs.png"}
                      alt="Plug2Jobs"
                    />
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-lg-3">
                <div className="testimonial-grid  layoutStyle ">
                  <span className="social-media">Job Seeker</span>
                  <p>
                    Stay up to date on the latest jobs around the country and
                    build your job profile for different employers to see .
                  </p>
                  <div className="author-info">
                    <div className="thumb">
                      <img
                        src={process.env.PUBLIC_URL + data.authorimg}
                        alt={ff}
                      />
                    </div>
                    <div className="content">
                      <Button variant="dark">Proceed to Apply</Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="testimonial-grid  layoutStyle ">
                  <span className="social-media">Agent</span>
                  <p>
                    Connect Job Seekers to employers and stay updated on latest
                    jobs available.
                  </p>
                  <div className="author-info">
                    <div className="thumb">
                      <img
                        src={process.env.PUBLIC_URL + data.authorimg}
                        alt={ff}
                      />
                    </div>
                    <div className="content">
                      <Button variant="dark">Connect Now </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="testimonial-grid  layoutStyle ">
                  <span className="social-media">Employer</span>
                  <p>
                    Create job openings and get connnected to suitable employers
                    for available roles.
                  </p>
                  <div className="author-info">
                    <div className="thumb">
                      <img
                        src={process.env.PUBLIC_URL + data.authorimg}
                        alt={ff}
                      />
                    </div>
                    <div className="content">
                      <Button variant="dark">Connect to Employees</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <CareerTwo />
        <CareerOne />

        {/* <VideoOne /> */}
        {/* <ProjectOne parentclassName="bg-color-light" />
        <TestimonialOne /> */}
        {/* <BrandOne /> */}
        {/* <BlogOne /> */}
        {/* <CtaLayoutOne /> */}
        <div style={{ paddingBottom: "40px" }} className="container mt-4 mb-4 ">
          <div className="jumbotron">
            <h1 className="display-4">Work & Fun at Emplug</h1>
            <p className="lead ">
              We make doing what we do really exciting and while we're taking a
              break from building a working nation, we have fun activites
              prepared for our staff.
            </p>
          </div>

          <div className="row">
            {/* <div className="col-lg-3 col-md-4">
              <img
                src={process.env.PUBLIC_URL + "/images/act9.jpeg"}
                className="img-thumbnail "
              />
            </div> */}
            <div className="col-lg-3 col-md-4">
              <img
              
                src={process.env.PUBLIC_URL + "/images/banner/fun1.jpg"}
                className="img-thumbnail "
              />
            </div>
            {/* <div className="col-lg-3 col-md-4">
              <img
                src={process.env.PUBLIC_URL + "/images/act6.jpeg"}
                className="img-thumbnail "
              />
            </div> */}
            <div className="col-lg-3 col-md-4">
              <img
                src={process.env.PUBLIC_URL + "/images/act1.jpg"}
                className="img-thumbnail "
              />
            </div>
            <div className="col-lg-3 col-md-4">
              <img
                src={process.env.PUBLIC_URL + "/images/banner/fun2.jpg"}
                className="img-thumbnail "
              />
            </div>
            <div className="col-lg-3 col-md-4">
              <img
                src={process.env.PUBLIC_URL + "/images/act7.jpeg"}
                className="img-thumbnail "
              />
            </div>
            <div className="col-lg-3 col-md-4">
              <img
                src={process.env.PUBLIC_URL + "/images/act2.jpg"}
                className="img-thumbnail "
              />
            </div>
            <div className="col-lg-3 col-md-4">
              <img
                src={process.env.PUBLIC_URL + "/images/banner/fun4.jpg"}
                className="img-thumbnail "
              />
            </div>
            <div className="col-lg-3 col-md-4">
              <img
                src={process.env.PUBLIC_URL + "/images/act5.jpg"}
                className="img-thumbnail "
              />
            </div>
            <div className="col-lg-3 col-md-4">
              <img
                src={process.env.PUBLIC_URL + "/images/banner/fun5.jpg"}
                className="img-thumbnail "
              />
            </div>
            <div className="col-lg-3 col-md-4">
              <img
                src={process.env.PUBLIC_URL + "/images/act3.jpg"}
                className="img-thumbnail "
              />
            </div>
            <div className="col-lg-3 col-md-4">
              <img
                src={process.env.PUBLIC_URL + "/images/banner/fun6.jpg"}
                className="img-thumbnail "
              />
            </div>
            {/* <div className="col-lg-3 col-md-4">
              <img
                src={process.env.PUBLIC_URL + "/images/act7.jpeg"}
                className="img-thumbnail "
              />
            </div> */}
            <div className="col-lg-3 col-md-4">
              <img
                src={process.env.PUBLIC_URL + "/images/act4.jpg"}
                className="img-thumbnail "
              />
            </div>
            <div className="col-lg-3 col-md-4">
              <img
                src={process.env.PUBLIC_URL + "/images/act10.jpeg"}
                className="img-thumbnail "
              />
            </div>
            {/* <div className="col-lg-3 col-md-4">
              <img
                src={process.env.PUBLIC_URL + "/images/act8.jpeg"}
                className="img-thumbnail "
              />
            </div> */}
            {/* 
            <div className="col-lg-3 col-md-4">
              <img
                src="https://images.unsplash.com/photo-1511899135790-0af61b1986f7?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=4ef514b84620b3bf6f588f8b25749309&auto=format&fit=crop&w=1350&q=80"
                className="img-thumbnail "
              />
            </div>
            <div className="col-lg-3 col-md-4">
              <img
                src="https://images.unsplash.com/photo-1523057530100-383d7fbc77a1?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=cff1b2c9744c71e84145dd78928513fc&auto=format&fit=crop&w=1349&q=80"
                className="img-thumbnail "
              />
            </div>
            <div className="col-lg-3 col-md-4">
              <img
                src="https://images.unsplash.com/photo-1496660067708-010ebdd7ce72?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=ea3514e6e00d8ce25c24d992b97929d9&auto=format&fit=crop&w=1350&q=80"
                className="img-thumbnail "
              />
            </div> */}
          </div>
        </div>

        <FooterOne />
      </main>
    </>
  );
};

export default Career;
