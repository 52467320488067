import React from 'react';
import { Link } from 'react-router-dom';


const Logo = ({limage, dimage, simage}) => {
    return (
      <Link to={process.env.PUBLIC_URL + "/"}>
        <img
          className="light-version-logo "
          style={{ width: "180px" }}
          src={process.env.PUBLIC_URL + limage}
          alt="logo"
        />
         <img
          className="dark-version-logo"
          style={{ width: "180px" }}
          src={process.env.PUBLIC_URL + dimage}
          
        />
        <img
          className="sticky-logo"
          style={{ width: "180px" }}
          src={process.env.PUBLIC_URL + simage}
         
        />
      </Link>
    );
}


export default Logo;