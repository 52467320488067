import React, { useState, useEffect } from "react";
import TextLoop from "react-text-loop";
import cxs from "cxs/component";
// Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
import { Link } from "react-router-dom";
import { FaLongArrowAltDown } from "react-icons/fa";

const Example = cxs("div")({
  fontSize: "18px",
});

const Title = cxs("div")({
  fontSize: "10px",
  fontWeight: 600,
  textTransform: "uppercase",
  color: "#aaa",
});

const Section = cxs("div")({
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
});

const StyledTextLoop = cxs(TextLoop)({
  display: "block",
});

const CtaLayoutOne = () => {
  return (
    <div className="section call-to-action-area">
      <div className="container">
        <div className="call-to-action">
          <div className="section-heading heading-light mb-md-5">
            <Example>
              <div className="text-change">
                <div className="pr-2">
                  <h4 className="title">Need</h4>
                </div>
                <div>
                  <TextLoop
                    interval={3000}
                    springConfig={{ stiffness: 70, damping: 31 }}
                  >
                    <h4 className="title" style={{ color: "#06194A" }}>
                      {" "}
                      a Job ?
                    </h4>
                    <h4 className="title" style={{ color: "#06194A" }}>
                      to Hire Staff ?
                    </h4>
                    <h4 className="title" style={{ color: "#C11F38" }}>
                      more Customers ?
                    </h4>
                    <h4 className="title" style={{color:"#348D43"}}>free Career Support ?</h4>
                    <h4 className="title" style={{ color: "#C11F38" }}>
                      more Sales ?
                    </h4>
                    <h4 className="title" style={{ color: "#C11F38" }}>
                      to Provide Services ?
                    </h4>
                    <h4 className="title" style={{ color: "#C11F38" }}>
                      to Request Services ?
                    </h4>
                    <h4 className="title" style={{ color: "#C11F38" }}>
                      to Access Funds ?
                    </h4>
                  </TextLoop>{" "}
                </div>
              </div>
            </Example>
            <div className="d-block">
              <span className="subtitle-cta">All these and more solved !</span>
              {/* <div className="object-up">
                <FaLongArrowAltDown size={40} color="white" />
              </div> */}
            </div>
          </div>

          <div className="row ">
            <div className="section-heading heading-light col-12 col-md-4 section-heading-left ">
              <div>
                <img
                  style={{ width: "100px" }}
                  className="mr-sm-4 mt-md-2"
                  src={process.env.PUBLIC_URL + "/images/iconx.png"}
                  alt="Comments"
                />
              </div>
              <Link
                to={process.env.PUBLIC_URL + "/contact"}
                className="axil-btn btn-large btn-fill-red hover:btn-fill-black"
              >
                Get Plugged Now !
              </Link>
            </div>
            <div className="section-heading heading-lightcol-12 col-md-4 mt-4 mt-md-0">
              <div>
                <img
                  style={{
                    width: "50px",
                  }}
                  className="mb-4 mr-sm-4 "
                  src={process.env.PUBLIC_URL + "/images/plug2jobsicon.png"}
                  alt="Comments"
                />
              </div>
              <Link
                to={process.env.PUBLIC_URL + "/contact"}
                className="axil-btn btn-large btn-fill-jobs hover:btn-fill-black"
              >
                Find Jobs Now !
              </Link>
            </div>
            <div className="section-heading heading-light col-12 col-md-4 section-heading-left mt-4">
              <div>
                <img
                  style={{ width: "80px" }}
                  className="mb-4 mb-md-4  mr-md-0 mt-4 mt-md-0"
                  src={process.env.PUBLIC_URL + "/images/careerclinic.png"}
                  alt="Comments"
                />
              </div>
              <Link
                to={process.env.PUBLIC_URL + "/contact"}
                className="axil-btn btn-large btn-fill-clinik  hover:btn-fill-black"
              >
                Launch Your Career !
              </Link>
            </div>
          </div>

          <div className="thumbnail">
            <div
              className="larg-thumb"
              data-sal="zoom-in"
              data-sal-duration="600"
              data-sal-delay="100"
            >
              <img
                className="paralax-image"
                src={process.env.PUBLIC_URL + "/images/others/chat-group.png"}
                alt="Chat"
              />
            </div>
            <ul className="list-unstyled small-thumb">
              <li
                className="shape shape-1"
                data-sal="slide-right"
                data-sal-duration="800"
                data-sal-delay="400"
              >
                <img
                  className="paralax-image"
                  src={
                    process.env.PUBLIC_URL + "/images/others/laptop-poses.png"
                  }
                  alt="Laptop"
                />
              </li>
              <li
                className="shape shape-2"
                data-sal="slide-left"
                data-sal-duration="800"
                data-sal-delay="300"
              >
                <img
                  className="paralax-image"
                  src={process.env.PUBLIC_URL + "/images/others/bill-pay.png"}
                  alt="Bill"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ul className="list-unstyled shape-group-9">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-12.png"}
            alt="Comments"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"}
            alt="Comments"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-13.png"}
            alt="Comments"
          />
        </li>
        <li className="shape shape-4">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
            alt="Comments"
          />
        </li>
        <li className="shape shape-5">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"}
            alt="Comments"
          />
        </li>
        <li className="shape shape-6">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-15.png"}
            alt="Comments"
          />
        </li>
        <li className="shape shape-7">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"}
            alt="Comments"
          />
        </li>
      </ul>
    </div>
  );
};

export default CtaLayoutOne;
