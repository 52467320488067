import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

const CareerOne = () => {
  return (
    <div className="section richard-section  case-study-featured-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <h3>Studentpreneurship</h3>
                <p>
                  Join our student talent competitions to have your business
                  idea funded as s student and don’t bother looking for a job by
                  the time you leave school.
                </p>
                <Button variant="dark">Apply on Build Program</Button>
                {/* <Link to="#" className="axil-btn btn-large btn-fill-primary">
                    Our Team
                  </Link> */}
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured">
              <div className="thumbnail">
                <img
                  src={process.env.PUBLIC_URL + "/images/banner/intern.jpg"}
                  alt="thumbnail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerOne;
