import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Tilty from 'react-tilty';
import Logo from "../../elements/logo/Logo";


const BannerFour = () => {
    return (
      <div className="banner banner-style-4">
        
        <div className="container">
          <div className="banner-content">
            <h1 className="title">Connecting people all day, everyday...</h1>
            <p>
              Emplug enables everyone to plug to Businesses, Job Opportunities, Security, Learning, Hiring Services, Funds, Advertising and to connect with friends and family. The Emplug Eco-System is a trusted service community for you to create wealth, grow your business or just have fun.
            </p>
           
            <div>
              <Link
                to={process.env.PUBLIC_URL + "/project-grid-one"}
                className="axil-btn btn-fill-primary btn-large"
              >
                Let's get Started
              </Link>
            </div>
          </div>
          <div className="banner-thumbnail">
            <div className="large-thumb">
              <Tilty perspective={3000}></Tilty>
            </div>
          </div>
         
        </div>
        <ul className="list-unstyled shape-group-21">
          <li className="shape shape-1">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-39.png"}
              alt="Bubble"
            />
          </li>
          <li className="shape shape-2">
            <img
              src={process.env.PUBLIC_URL + "/images/others/line-7.png"}
              alt="Bubble"
            />
          </li>

          <li class="shape shape-2">
            <img src="/images/others/bubble-38.png" alt="Bubble" />
          </li>
          {/* <li class="shape shape-3">
            <img src="/images/others/bubble-14.png" alt="Bubble" />
          </li> */}
        </ul>
        <ul className="list-unstyled shape-group-9">
          <li className="shape shape-1">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-12.png"}
              alt="Comments"
            />
          </li>
          <li className="shape shape-2">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"}
              alt="Comments"
            />
          </li>
          <li className="shape shape-3">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-13.png"}
              alt="Comments"
            />
          </li>
          <li className="shape shape-4">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
              alt="Comments"
            />
          </li>
          <li className="shape shape-5">
            <img
              style={{ width: "2%" }}
              className=""
              src={process.env.PUBLIC_URL + "/images/emplugacademyicon.png"}
              alt="Comments"
            />
          </li>

          <li className="shape shape-7">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"}
              alt="Comments"
            />
          </li>
          <li className="shape shape-7">
            <img
              style={{ width: "5%" }}
              className=""
              src={process.env.PUBLIC_URL + "/images/iconx.png"}
              alt="Comments"
            />
          </li>

          <li className="shape shape-6">
            <img
              style={{ width: "5%" }}
              src={process.env.PUBLIC_URL + "/images/farmless.png"}
              alt="Bubble"
            />
          </li>
          <li className="shape shape-7">
            <img
              style={{ width: "2%" }}
              className=""
              src={process.env.PUBLIC_URL + "/images/reportamicon.png"}
              alt="Comments"
            />
          </li>
          <li className="shape shape-8 marque-images" />
        </ul>
      </div>
    );
}

export default BannerFour;