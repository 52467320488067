import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import Button from "react-bootstrap/Button";

const ProgramSix = () => {
  return (
    <div className="section  case-study-featured-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-6">
            <div className="case-study-featured">
              <img
                src={process.env.PUBLIC_URL + "/images/banner/expo.jpg"}
                alt="travel"
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left ">
                <h3 className="title">The Expo</h3>
                <p className="fs-5">
                  The Emplug Expo is a 2 day multidimensional platform which
                  creates solutions for the workplace, connects stakeholders in
                  the education space and plays host to a unique networking
                  event of employers and job seekers. It is an opportunity for a
                  glimpse into the future of work with on the spot job
                  interviews, discover the life changing possibilities with some
                  of our outstanding educational institutions at the Expo with
                  on the spot admissions for eligible students and a lunch meet
                  up with outstanding industry professionals in a fun filled
                  networking atmosphere.
                </p>
                <Button variant="outline-success">Read More</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramSix;
