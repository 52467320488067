import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import Button from "react-bootstrap/Button";

const ProgramEight = () => {
  return (
    <div className="section  case-study-featured-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-6">
            <div className="case-study-featured">
              <img
                src={process.env.PUBLIC_URL + "/images/banner/plugbayy.jpg"}
                alt="travel"
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left ">
                <h3 className="title">The Plugbay Hub</h3>
                <p className="fs-5">
                  PlugBay is designed to provide multi-level intervention
                  through its simple yet innovative solutions in the areas of
                  employment creation, sustainable livelihood, Skills Education
                  and social inclusion. Through a multi-pronged approach,
                  PlugBay connects and provides essential livelihood solutions
                  to the informal sector workers across different sectors of the
                  economy. Often engaging a multi-layered stakeholders
                  collaboration involving Government, Corporate organizations,
                  Non profits and Development agencies.
                </p>
                <Button variant="outline-success">Read More</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramEight;
