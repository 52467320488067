import React, { useState, useEffect } from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ServicePropOne from "../component/service/ServicePropOne";
import AboutThree from "../component/about/AboutThree";
import CounterUpTwo from "../component/counterup/CounterUpTwo";
import PricingData from "../data/pricing/PricingData.json";
import { FaCheck } from "react-icons/fa";
import ServicePropTwo from "../component/service/ServicePropsTwo";



const getPriceingData = PricingData;

const billed = [
  {
    id: 1,
    label: "Social Enterprise Apps",
  },
  {
    id: 2,
    label: "Social Impact Programs",
  },
];



    
const Ecosystem = () => {
  const [isActive, setActive] = useState("");

    useEffect(() => {
        setActive(billed[0].label.toLowerCase());
		
    }, []);


    const handleChange = (e) => {
        setActive(e.target.textContent.toLowerCase());
        
    };
    
  return (
    <>
      <SEO title="Ecosystem" />

      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        {/* <BcrumbBannerOne
          title="Best solutions for your business"
          paragraph="Give your business a unique logo to stand out from crowd. We’ll create logo specifically for your company.
                "
          styleClass=""
          mainThumb="/images/banner/banner-thumb-4.png"
        /> */}
        {/* <CounterUpTwo /> */}
        <div className="section section-padding bg-color-light richard-padding">
          <div className="container">
            <SectionTitle
              subtitle=""
              title="Services we can <br> help you with"
              description=""
              textAlignment="heading-left"
              textColor=""
            />
            <div className="pricing-billing-duration">
              <ul>
                {billed.map((data) => (
                  <li className="nav-item" key={data.id}>
                    <button
                      onClick={handleChange}
                      className={`nav-link ${
                        data.label.toLocaleLowerCase() === isActive
                          ? "active"
                          : ""
                      }`}
                    >
                      {data.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="row">
              {billed[0].label.toLowerCase() === isActive ? (
                <div>
                  <div className="row">
                    <ServicePropOne
                      colSize="col-lg-4 col-md-4"
                      serviceStyle="service-style-2"
                      itemShow="11"
                      marginTop="no"
                    />
                  </div>
                </div>
              ) : (
                <div>
                <div className="row">
                  <ServicePropTwo
                    colSize="col-lg-4 col-md-4"
                    serviceStyle="service-style-2"
                    itemShow="11"
                    marginTop="no"
                  />
                </div>
              </div>
              )}
              {/* {getPriceingData.map((data, index) => (
                    <div className="col-lg-4" key={index}>
                        <div className="pricing-table pricing-borderd">
                            <div className="pricing-header">
                                <h3 className="title">{data.title}</h3>
                                <span className="subtitle">{data.subtitle}</span>
                                <div className="price-wrap">
                                    <div className="yearly-pricing">
                                        <span className="amount">{billed[0].label.toLowerCase() === isActive ? data.yprice : data.mprice }</span>
                                        <span className="duration">{billed[0].label.toLowerCase() === isActive ? data.yduration : data.mduration }</span>
                                    </div>
                                </div>
                                <div className="pricing-btn">
                                    <Link to="#" className="axil-btn btn-large btn-borderd">Get Started Today</Link>
                                </div>
                            </div>
                            <div className="pricing-body">
                                <ul className="list-unstyled">
                                    {data.facility.map((data, index) => (
                                        <li key={index}><FaCheck /> {data}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ))} */}
            </div>
          </div>
        </div>

        {/* <AboutThree /> */}
        {/* 
        <CtaLayoutOne /> */}
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default Ecosystem;
