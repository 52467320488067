import React from "react";
import Accordion from "react-bootstrap/Accordion";
import SectionTitle from "../../elements/section-title/SectionTitle";
import { FaCompress, FaCode, FaGlobe } from "react-icons/fa";
import { Link } from "react-router-dom";

const WhyChooseOne = () => {
  return (
    <div className="section-padding bg-color-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="why-choose-us">
              <SectionTitle
                subtitle="Why Choose Emplug ?"
                title="Everyone just loves plugging..."
                description="
Emplug is pioneering the world's first Social Enterprise hub for your integrated  acccess to solutions to everyday needs. Members of the Emplug community are connected to amazing solutions, including the undeserved informal sector players in the most remote locations of our world ."
                textAlignment="heading-left"
                textColor=""
              />
              <Link
                to={process.env.PUBLIC_URL + "/project-grid-one"}
                className="axil-btn btn-fill-primary btn-medium"
              >
                Get Plugged Now
              </Link>
              {/* <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <FaCompress /> Strategy
                  </Accordion.Header>
                  <Accordion.Body>
                    Aenean hendrerit laoreet vehicula. Nullam convallis augue at
                    enim gravida pellentesque.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <FaCode /> Design
                  </Accordion.Header>
                  <Accordion.Body>
                    Aenean hendrerit laoreet vehicula. Nullam convallis augue at
                    enim gravida pellentesque.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <FaGlobe /> Development
                  </Accordion.Header>
                  <Accordion.Body>
                    Aenean hendrerit laoreet vehicula. Nullam convallis augue at
                    enim gravida pellentesque.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion> */}
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="why-choose-us mb--30">
              <div className="why-choose-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/images/plug.png"}
                  alt="Office"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseOne;
