import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import Button from "react-bootstrap/Button";

const ProgramTen = () => {
  return (
    <div className="section  case-study-featured-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-6">
            <div className="case-study-featured">
              <img
                src={process.env.PUBLIC_URL + "/images/banner/women.jpg"}
                alt="travel"
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left ">
                <h3 className="title">Women in Tech</h3>
                <p className="fs-5">
                  Emplug has observed the gender gap in technology especially in
                  sub-Sahara Africa and thus, championing the case of women
                  inclusion in technology. To this end, we partner with
                  governments and corporations to avail women and girls in tech
                  all the tools and resources they need to excel.
                </p>
                <Button variant="outline-success">Read More</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramTen;
