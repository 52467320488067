import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

const ProgramNine = () => {
  return (
    <div className="section-padding case-study-featured-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <h3>Plug to Funds</h3>
                <p className="fs-5">
                  Feature me is Emplug’s extended funding program that allows
                  members with business ideas or already established businesses
                  to be featured in Emplug crowd funding platform.
                </p>
                <Button variant="outline-success">Read More</Button>
                {/* <Link to="#" className="axil-btn btn-large btn-fill-primary">
                    Our Team
                  </Link> */}
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured">
              <div className="thumbnail">
                <img
                  className="w-100"
                  src={process.env.PUBLIC_URL + "/images/banner/funds.jpg"}
                  alt="thumbnail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramNine;
