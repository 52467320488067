import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

const ProgramOne = () => {
  return (
    <div className="section richard-section  case-study-featured-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <h3>The Build Program</h3>
                <p className="fs-5">
                  The Emplug Build Program is a start-up stage business funding
                  initiative for members of the Emplug community. It supports
                  Innovative and visionary entrepreneurs within the Emplug
                  community with the resources, networks and guidance needed to
                  build successful businesses. Participants in the Build program
                  are provided with a structured support system through their
                  entrepreneurship journey, from launch to the growth stage of
                  the business life cycle.{" "}
                </p>
                <Button variant="outline-success">Read More</Button>
                {/* <Link to="#" className="axil-btn btn-large btn-fill-primary">
                    Our Team
                  </Link> */}
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured">
              <div className="thumbnail">
                <img
                  src={process.env.PUBLIC_URL + "/images/build.jpg"}
                  alt="thumbnail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramOne;
