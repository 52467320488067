import React from 'react';
import { Link } from 'react-router-dom';


const AboutFive = () => {
    return (
      <div className="section-padding case-study-featured-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-6">
              <div className="case-study-featured">
                <div className="section-heading heading-left">
                  <h1>Our Vision</h1>
                  <p>
                    Our Vision is to build a working nation where everyone is a
                    partner and where the informal sector can be mainstreamed
                    into the economy.
                  </p>
                  {/* <Link to="#" className="axil-btn btn-large btn-fill-primary">
                    Our Team
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6">
              <div className="case-study-featured">
                <div className="thumbnail">
                  <img
                    src="https://images.unsplash.com/photo-1511632765486-a01980e01a18?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                    alt="thumbnail"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default AboutFive;