import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BannerFour from "../component/banner/BannerFour";
import BlogOne from "../component/blog/BlogOne";
import BrandOne from "../component/brand/BrandOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectFour from "../component/project/ProjectFour";
import TestimonialOne from "../component/testimonial/TestimonialOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import WhyChooseOne from "../component/whychoose/WhyChooseOne";
import { useState, useEffect } from "react";

const HomeStartup = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);
  return (
    <>
      {loading ? (
        <div className="center-spinner ">
          <div className="spinner-grow text-primary" role="status">
            
          </div>
          <div className="spinner-grow text-secondary" role="status">
            
          </div>
          <div className="spinner-grow text-success" role="status">
            
          </div>
          <div className="spinner-grow text-danger" role="status">
            
          </div>
          <div className="spinner-grow text-warning" role="status">
            
          </div>
          <div className="spinner-grow text-info" role="status">
            
          </div>
          <div className="spinner-grow text-primary" role="status">
            
          </div>
          <div className="spinner-grow text-dark" role="status">
            
          </div>
        </div>
      ) : (
        <div>
          <SEO title="Home Startup" />
          <ColorSwitcher />
          <main className="main-wrapper">
            <HeaderOne />

            <BannerFour />

            <WhyChooseOne />
            <BrandOne />

            {/* <ProjectFour /> */}
            {/* <TestimonialOne /> */}

            {/* <BlogOne /> */}
            <CtaLayoutOne />
            <FooterOne />
          </main>
        </div>
      )}
    </>
  );
};

export default HomeStartup;
