import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import Button from "react-bootstrap/Button";

const ProgramFour = () => {
  return (
    <div className="section  case-study-featured-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-6">
            <div className="case-study-featured">
              <img
                src={process.env.PUBLIC_URL + "/images/fund4dream.png"}
                alt="travel"
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left ">
                <h3 className="title">Funds4Dreams</h3>
                <p className="fs-5">
                  Funds4Dream is a non-profit organization committed to
                  facilitating sustainable solutions for some of the critical
                  challenges facing humanity today. Through a structured
                  process, we connect challenges to the resources needed for
                  their actualization. At Funds4Dreams our beneficiaries are
                  working partners as we create the change we want to see.
                </p>
                <Button variant="outline-success">Read More</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramFour;
