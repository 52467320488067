import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

const CareerThree = () => {
  return (
    <div className="section richard-section  case-study-featured-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <h3>Employee Benefits</h3>
                <p>
                  At Emplug , we offer a comprehensive benefits package to our
                  employees. Our health, welfare and financial benefits are
                  designed to meet the diverse needs and preferences of our
                  employees. We offer healthcare choices to our employees and
                  their families, including domestic partners, which provide
                  access to a broad array of providers while giving employees
                  tools to make informed decisions about their care. Our
                  disability, life and long-term care benefits help to protect
                  our employees from the financial consequences of illness,
                  accident or death. Financial plans are offered to help our
                  employees with their income replacement needs when they
                  retire. We continually review our benefit plans to ensure they
                  are competitive, affordable and appreciated by our employees.
                </p>
               
                {/* <Link to="#" className="axil-btn btn-large btn-fill-primary">
                    Our Team
                  </Link> */}
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="thumbnail">
                <img
                  src={process.env.PUBLIC_URL + "/images/benefit.jpg"}
                  alt="thumbnail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerThree;
