import React from "react";
import { Link } from "react-router-dom";

const Data = [
  {
    image: "/images/logo2.png",
    link:"www.plugbay.com"
  },
  {
    image: "/images/careerclinik.png",
    link:"www.careerclinic.us"
  },
  {
    image: "/images/plug2jobs.png",
    link:"/coming-soon"
  },
  {
    image: "/images/crowdamlogo.png",
    link:"www.crowdam.com"
  },
  {
    image: "/images/emplugacademy.png",
    link:"/coming-soon"
  },
  {
    image: "/images/emchatnew.png",
    link:"/coming-soon"
  },
  {
    image: "/images/reportam.png",
    link:"/coming-soon"
  },
  {
    image: "/images/plugamlogo.png",
    link:"/coming-soon"
  },
  {
    image: "/images/plug2ad.png",
    link:"www.plug2ads.com"
  },
  {
    image: "/images/codahub1.png",
    link:"/coming-soon"
  },
  {
    image: "/images/plugexlogo.png",
    link:"/coming-soon"
  },
  {
    image: "/images/farmlesslg.png",
    link:"/coming-soon"
  },

  //   {
  //     image: "/images/konas.png",
  //   },
];

const BrandItem = () => {
  return (
    <>
      {Data.map((data, index) => (
        <div className="col-lg-3 col-6" key={index}>
          <div className="brand-grid">
            <Link to={data.link}>
            <img className="w-75" src={process.env.PUBLIC_URL + data.image} alt="Brand" />
            </Link>
          </div>
        </div>
      ))}
    </>
  );
};

export default BrandItem;
