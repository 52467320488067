import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';


const AboutFour = () => {
    return (
      <div className="section section-padding case-study-featured-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-6">
              <div className="case-study-featured">
                <img
                  src="https://images.unsplash.com/photo-1474631245212-32dc3c8310c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1024&q=80"
                  alt="travel"
                />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="case-study-featured">
                <div className="section-heading heading-left mt-md-5">
                  <h1 className="title">Our Mission</h1>
                  <p>
                    Our core mandate is to create massive empowerment
                    opportunities for all without discrimination.
                  </p>

                  {/* <Link to="#" className="axil-btn btn-fill-primary btn-large">
                    Discover ! 
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default AboutFour;