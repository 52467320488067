import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';


const PrivacyPolicy = () => {
    const {pathname} = location
 const [active1, setActive1] = useState(false);
 const [active2, setActive2] = useState(false);
 const [active3, setActive3] = useState(false);
 const [active4, setActive4] = useState(false);
 const activeClick = () => {
   setActive1(true);
   setActive2(false);
   setActive3(false);
   setActive4(false);
 };
 const activeClick2 = () => {
   setActive2(true);
   setActive1(false);
   setActive3(false);
   setActive4(false);
 };
 const activeClick3 = () => {
   setActive3(true);
   setActive1(false);
   setActive2(false);
   setActive4(false);
 };
 const activeClick4 = () => {
   setActive3(false);
   setActive1(false);
   setActive2(false);
   setActive4(true);
 };
    return (
      <>
        <SEO title="Privacy Policy" />
        <ColorSwitcher />
        <main className="main-wrapper">
          <HeaderOne />
          <BreadCrumbOne title="Terms & Policies" page="Privacy Policy" />
          <div className="section-padding privacy-policy-area">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-4">
                  <div className="list-group mb-sm-2">
                    <Link
                      to="/terms&policies"
                      onClick={activeClick}
                      className={
                        active1
                          ? "list-group-item list-group-item-action active"
                          : "list-group-item list-group-item-action"
                      }
                    >
                      Terms of Service
                    </Link>
                    <Link
                      to="/privacy-policy"
                      onClick={activeClick2}
                      className={`
                        list-group-item list-group-item-action active &&  ${
                          pathname.includes("terms&policies") &&
                          "list-group-item list-group-item-action"
                        } `}
                    >
                      Privacy Policies
                    </Link>
                    <Link
                      to="/cookie-policy"
                      onClick={activeClick3}
                      className={
                        active3
                          ? "list-group-item list-group-item-action active"
                          : "list-group-item list-group-item-action"
                      }
                    >
                      Cookie Policies
                    </Link>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="privacy-policy-content">
                    <div className="section-title">
                      <h5 className="title">Privacy Policies</h5>
                    </div>
                    <h5>1. GDPR compliance</h5>
                    <p>
                      Sed nec ex vitae justo molestie maximus. Sed ut neque sit
                      amet libero rhoncus tempor. Fusce tempor quam libero,
                      varius congue magna tempus vitae. Donec a justo nec elit
                      sagittis sagittis eu a ante. Vivamus rutrum elit odio.
                      Donec gravida id ligula ut faucibus. Aenean convallis
                      ligula orci, ut congue nunc sodales ut. In ultrices elit
                      malesuada velit ornare, eget dictum velit hendrerit.
                      Praesent bibendum blandit lectus, eu congue neque mollis
                      in. Pellentesque metus diam, hendrerit in purus fringilla,
                      accumsan bibendum sapien. Nunc non facilisis sem.
                    </p>
                    <div id="privacy">
                      <h5>2. Privacy Policies</h5>
                      <p>
                        At EMPLUG, we recognize and value our customers'
                        privacy. As such, EMPLUG is committed to ensuring that
                        its customers are informed how their personal
                        information will be used and kept secure. This privacy
                        policy applies to information collected by EMPLUG and
                        its affiliates through the websites, products and
                        services that display this privacy policy, including
                        products and services that may be provided by EMPLUG
                        off-line. This privacy policy applies to all EMPLUG
                        products and services currently offered by EMPLUG. BY
                        VISITING EMPLUG'S WEBSITE, OR BY USING OR PURCHASING THE
                        PRODUCTS AND SERVICES OFFERED BY EMPLUG, YOU ACCEPT THE
                        PRACTICES DESCRIBED IN THIS PRIVACY POLICY AND CONSENT
                        TO EMPLUG'S USE OF YOUR INFORMATION IN THE MANNER
                        DESCRIBED IN THIS PRIVACY POLICY.
                      </p>
                      <hr />
                      <h5>1. INFORMATION THAT EMPLUG COLLECTS</h5>
                      <p>
                        EMPLUG may collect information about you your identity
                        and personal preferences as they relate to our products
                        and services. Personal information we may typically
                        collect includes your name, postal address, email
                        address and telephone number. EMPLUG will try to collect
                        only information that is necessary for the purpose
                        EMPLUG is collecting the information. There are a number
                        of different ways that EMPLUG receives and collects
                        personal information from you. These may include:
                      </p>
                      <ul>
                        <li>
                          <hr />
                          <h6> Registration</h6>
                          <p>
                            Upon registration, you will be asked to provide
                            information that we will use to record you as an
                            EMPLUG user and to assist us in providing you with
                            service and information.
                          </p>
                        </li>
                        <li>
                          <hr />
                          <h6>Communications from You</h6>
                          <p>
                            When you email or write to us, you may provide us
                            with your name, contact details, and other personal
                            information in your message.
                          </p>
                        </li>
                        <li>
                          <hr />
                          <h6>Customer Support</h6>
                          <p>
                            You may provide us with personal information when
                            you telephone or email us to request technical
                            assistance with our services or information that you
                            would like to receive about
                          </p>
                        </li>
                      </ul>
                      <hr />
                      <h5>2. PURPOSES FOR WHICH EMPLUG USES INFORMATION</h5>
                      <p>
                        EMPLUG may use your personal information for the
                        following purposes:
                      </p>
                      <ul>
                        <li>
                          <hr />
                          <h6> Registration</h6>
                          <p>
                            Information you provide upon registration will be
                            used to keep a record of your. EMPLUG will also use
                            this information to inform you of any upgrades, new
                            services, promotions or other information that may
                            be of interest to you.
                          </p>
                        </li>
                        <li>
                          <hr />
                          <h6> Product Installation and Use</h6>
                          <p>
                            Information gathered during installation may be used
                            by EMPLUG authenticate and periodically verify your
                            licensing rights in the product for anti-piracy
                            purposes identify if new product updates are
                            available for your computer prior to sending you a
                            notice to install the new product update; and
                            receive in-product messages from EMPLUG that you
                            have requested to receive
                          </p>
                        </li>
                        <li>
                          <hr />
                          <h6>Product/Services Purchases</h6>
                          <p>
                            When you purchase products/services from, EMPLUG
                            will use your information to process your payment..
                          </p>
                        </li>
                        <li>
                          <hr />
                          <h6>Internal Analysis</h6>
                          <p>
                            We may use the information you provide us with for
                            our own internal statistical and analytical
                            purposes, to evaluate and enhance users' experience
                            of the Site by identifying customer preferences and
                            purchasing trends that we may use for marketing
                            purposes and in respect of operations and
                            development.
                          </p>
                        </li>
                        <li>
                          <hr />
                          <h6> Updating Information</h6>
                          <p>
                            We may use the information you provide us with for
                            our own internal statistical and analytical
                            purposes, to evaluate and enhance users' experience
                            of the Site by identifying customer preferences and
                            purchasing trends that we may use for marketing
                            purposes and in respect of operations and
                            development.
                          </p>
                        </li>
                      </ul>
                      <hr />
                      <h5>3. AUTOMATICALLY COLLECTED INFORMATION</h5>
                      <p>
                        We use Automatic Information to help us improve our
                        understanding of our users and their preferences. We
                        also use it to assist us in arranging relevant
                        advertising, commercial opportunities and competitions.
                        Automatic Information we collect also helps us to
                        develop our website and audit its use. Furthermore,
                        EMPLUG may share Automatic Information with its
                        co-branding partners in order to improve the content,
                        banners and promotions that you and other users will see
                        on our websites and in our products.
                      </p>
                      <hr />
                      <h5>4. SHARING OF INFORMATION WITH THIRD PARTIES</h5>
                      <p>
                        Except as specifically described in Section 2, this
                        Section 4, Section, EMPLUG will not share any personal
                        information with third parties without obtaining your
                        consent.
                      </p>
                      <ul>
                        <li>
                          <hr />
                          <h6> Affiliates and Subsidiaries</h6>
                          <p>
                            We may share the information that you have provided
                            to us with subsidiaries and affiliated companies of
                            EMPLUG who are required to comply with the terms of
                            this policy.
                          </p>
                        </li>
                        <li>
                          <hr />
                          <h6> Internal Operations</h6>
                          <p>
                            We may also share information that you have provided
                            to us with independent auditors, acting on our
                            behalf, for anti-piracy and revenue accounting
                            purposes in connection with the internal operations
                            of our business, provided that such companies comply
                            with the terms of this policy.
                          </p>
                        </li>
                        <li>
                          <hr />
                          <h6> Job Offers</h6>
                          <p>
                            As a registered EMPLUG member, you have the
                            opportunity to receive job offers from employers and
                            third parties who we think may offer opportunities
                            that would be of interest to you. We will share your
                            personal information with these third parties so
                            that they may send you information about their job
                            openings.
                          </p>
                        </li>
                      </ul>
                      <hr />
                      <h5>5. LEGAL DISCLOSURE</h5>
                      <p>
                        Although we make every effort to preserve user privacy,
                        we may need to disclose personal information when
                        required by law wherein we have a good-faith belief that
                        such action is necessary to comply with a current
                        judicial proceeding, a court order or other legal
                        process or to protect EMPLUG's, our users' or third
                        parties' rights, property or safety.
                      </p>
                      <hr />
                      <h5>6. SECURITY</h5>
                      <p>
                        We maintain reasonable physical, electronic and
                        procedural safeguards to protect your personal
                        information. While no data transmission over the
                        Internet is 100% secure from intrusion, we have used and
                        will continue to use commercially reasonable efforts to
                        ensure the protection of your personal information.
                      </p>
                      <hr />
                      <h5>7. LINKS</h5>
                      <p>
                        We may offer links from our website to other websites
                        (which may be hosted by third parties that we choose to
                        offer co-branded products with or by unrelated
                        companies) which we think may be of interest to you.
                        Third party websites are not governed by this privacy
                        policy and EMPLUG makes no representation as to their
                        practices for dealing with your personal information. We
                        recommend that you check the privacy policy of any
                        website at the beginning of your visit to determine its
                        practices.
                      </p>
                      <hr />
                      <h5>
                        8. CHAT ROOMS, NEWGROUPS, BLOGS AND OTHER INTERACTIVE
                        LOCATIONS
                      </h5>
                      <p>
                        EMPLUG's website may have chat rooms, newsgroups, blogs,
                        or other forums that permit individuals to interact
                        online. Some of these forums may allow you to post a
                        profile picture, share your gender and age and display
                        your user name or real name (if you choose to display
                        it). Any information you post in online forums will be
                        made public and EMPLUG cannot control nor is it
                        responsible for the use any third parties may make of
                        this information.
                      </p>
                      <hr />
                      <h5>9. CHILDREN</h5>
                      <p>
                        EMPLUG's website is a general audience site that is
                        neither designed nor intended to collect personal
                        information from children who are under the age of 18.
                        EMPLUG does not sell products/services for purchase by
                        children. We do not knowingly request personal
                        information from anyone under the age of 18 without
                        verifiable parental consent. We encourage parents and
                        guardians to supervise and join their children in
                        exploring the Internet and to help us protect their
                        privacy by instructing them not to provide personal
                        information over the Internet without parental
                        permission. Parents should additionally be aware of a
                        growing variety of software capable of filtering online
                        information to reflect their own choices and guidelines.
                        If we become aware that a user is under the age of 18
                        and has provided personal information to us without
                        prior verifiable parental consent, we will remove such
                        personal information from our records.
                      </p>
                      <hr />
                      <h5>
                        10. ACCESS, MODIFICATION AND ACCURACY OF INFORMATION
                      </h5>
                      <p>
                        We take reasonable steps to ensure that the information
                        that we receive from you is accurate and kept up to
                        date. You have the right to access your personal
                        information, to verify its accuracy, and to correct
                        inaccurate information. You can change your preferences
                        at any time by signing into your account through our
                        website with your login credentials.
                      </p>
                      <hr />
                      <h5>11. BUSINESS TRANSFERS</h5>
                      <p>
                        The information that is collected by EMPLUG is
                        considered to be an asset. In the event that EMPLUG, any
                        affiliated company, division or portions of its assets
                        are acquired by another company, such information may be
                        one of the transferred assets. If so, the acquiring
                        company may only use your information in ways consistent
                        with this privacy policy (or any subsequent policy in
                        effect at the time information is collected from you).
                        We note that information submitted or collected after a
                        transfer may be subject to a new privacy policy adopted
                        by the successor company.
                      </p>
                      <hr />

                      <h5>12. CHANGES TO PRIVACY POLICY</h5>
                      <p>
                        We are always looking for ways to improve our business
                        and our practices. Consequently, our policies will
                        continue to evolve. As such, EMPLUG reserves the right
                        to alter this privacy policy from time to time. Changes
                        to this policy will be posted to our EMPLUG.com website.
                        We encourage you to visit our privacy page from time to
                        time to view our most current privacy policy. If we make
                        material changes to this privacy policy that expand our
                        rights to use your personal information, we will notify
                        you and seek your consent through email or a prominent
                        posting on our website.
                      </p>
                      <hr />
                      <h5>13. CONTACT US</h5>
                      <p>
                        Any requests for access to your information,
                        modification of your information or questions in respect
                        of this policy should be sent to the email addresses or
                        phone numbers on the “contact us” page
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <CtaLayoutOne /> */}
          <FooterOne parentClass="" />
        </main>
      </>
    );
}

export default PrivacyPolicy;