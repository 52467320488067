import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

const ProgramSeven = () => {
  return (
    <div className="section-padding case-study-featured-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <h3>Plug Summit</h3>
                <p className="fs-5">
                  The Plug Summit is a gathering of some of the leading tech and
                  business leaders shaping the future. It is an annual event
                  from Lagos, Nigeria the heart of Africa (The Next Frontier).
                  The Plug Summit provides world-className experience and
                  lasting positive impression on participants beyond great
                  business networking opportunities. We are committed to
                  facilitating the emergence of a unique community of tech
                  innovators, change catalyst and ethical tech leaders who will
                  shape the future for humanity.
                </p>
                <Button variant="outline-success">Read More</Button>
                {/* <Link to="#" className="axil-btn btn-large btn-fill-primary">
                    Our Team
                  </Link> */}
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured">
              <div className="thumbnail">
                <img
                  className="w-100"
                  src={process.env.PUBLIC_URL + "/images/banner/summit.jpg"}
                  alt="thumbnail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramSeven;
