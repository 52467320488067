import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import Button from "react-bootstrap/Button";

const ProgramTwo = () => {
  return (
    <div className="section richard-section case-study-featured-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-6">
            <div className="case-study-featured">
              <img
                src={process.env.PUBLIC_URL + "/images/banner/hack.jpg"}
                alt="travel"
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left ">
                <h3 className="title">Plugathon</h3>
                <p className="fs-5">
                  Emplug powers one of the biggest Hackathons through the
                  Plugathon event making it a place where coding skills are put
                  to work and some real-world challenges are solved. Meet
                  Charity Hack, a special segment of the Plugathon where we
                  offer our skill and time to charitable causes. Emplug
                  organises hacking events to help solve the technology needs of
                  Non-Profits in local communities. Through our Charity Hack
                  sessions, we support Non-Profit actors with essential
                  technology tools needed to enhance their impact and
                  productivity.
                </p>
                <Button variant="outline-success">Read More</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramTwo;
