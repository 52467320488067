import React from "react";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import FooterOne from "../common/footer/FooterOne";
import BlogOne from "../component/blog/BlogOne";
import BrandOne from "../component/brand/BrandOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import TestimonialOne from "../component/testimonial/TestimonialOne";
import ProjectOne from "../component/project/ProjectOne";
import ServicePropOne from "../component/service/ServicePropOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import BannerSix from "../component/banner/BaannerSix";
import HeaderFour from "../common/header/HeaderFour";
import ProgramOne from "../component/programs/ProgramOne";
import ProgramTwo from "../component/programs/ProgramTwo";
import ProgramThree from "../component/programs/ProgramThree";
import ProgramFour from "../component/programs/ProgramsFour";
import ProgramFive from "../component/programs/ProgramFive";
import ProgramSix from "../component/programs/ProgramSix";
import ProgramSeven from "../component/programs/ProgramSeven";
import ProgramEight from "../component/programs/ProgramEight";
import ProgramNine from "../component/programs/ProgramNine";
import ProgramTen from "../component/programs/ProgramTen";

import { Button } from "react-bootstrap";

const Ads = () => {
  return (
    <>
      <SEO title="Corporate Agency" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderFour />
        <BannerSix />
        <div className="career-section">
          <SectionTitle
            subtitle=""
            title="Advertise with Emplug"
            description="We understand that advertising can be a headache which is why we have simplified it for everyone. From the individual advertiser,to the small business owner and even to the biggest global companies. Emplug has made it easy for you, even if you wish to advertise without data or a smartphone and in real-time..We've got you covered!"
            textAlignment=""
            textColor="mb--90"
          />
        </div>
        <div className="section section-padding customer-review-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <SectionTitle
                  subtitle=""
                  title="Advertise to the World"
                  description=""
                  textAlignment="heading-left"
                  textColor=""
                />
                <p className="col-lg-8 fs-5">
                  Do you wish to have your adverts shown in thousands of
                  publisher and partner websites around the world ? Then
                  Plug2Ads is your partner towards global reach with full
                  transparency over your adverts either as an advertiser or a
                  publisher. Experience real time global advertising at{" "}
                  <span style={{ color: "blue", textDecoration: "underline" }}>
                    www.plug2ads.com
                  </span>
                </p>
                {/* <div className="content">
                  <Button variant="dark">Proceed to Apply</Button>
                </div> */}
              </div>
              <div className="col-lg-3">
                <div className="review-site-logo">
                  <a href="https://www.plug2jobs.com/">
                    <img
                      src={process.env.PUBLIC_URL + "/images/plug2ad.png"}
                      alt="Plug2Jobs"
                    />
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-lg-3">
                <div className="testimonial-grid  layoutStyle ">
                  <span className="social-media">Job Seeker</span>
                  <p>
                    Stay up to date on the latest jobs around the country and
                    build your job profile for different employers to see .
                  </p>
                  <div className="author-info">
                    <div className="thumb">
                      <img
                        src={process.env.PUBLIC_URL + data.authorimg}
                        alt={ff}
                      />
                    </div>
                    <div className="content">
                      <Button variant="dark">Proceed to Apply</Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="testimonial-grid  layoutStyle ">
                  <span className="social-media">Agent</span>
                  <p>
                    Connect Job Seekers to employers and stay updated on latest
                    jobs available.
                  </p>
                  <div className="author-info">
                    <div className="thumb">
                      <img
                        src={process.env.PUBLIC_URL + data.authorimg}
                        alt={ff}
                      />
                    </div>
                    <div className="content">
                      <Button variant="dark">Connect Now </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="testimonial-grid  layoutStyle ">
                  <span className="social-media">Employer</span>
                  <p>
                    Create job openings and get connnected to suitable employers
                    for available roles.
                  </p>
                  <div className="author-info">
                    <div className="thumb">
                      <img
                        src={process.env.PUBLIC_URL + data.authorimg}
                        alt={ff}
                      />
                    </div>
                    <div className="content">
                      <Button variant="dark">Connect to Employees</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="section section-padding customer-review-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <SectionTitle
                  subtitle=""
                  title="Ads at the tip of your fingers"
                  description=""
                  textAlignment="heading-left"
                  textColor=""
                />
                <p className="col-lg-8 fs-5">
                  Send millions of adverts online via SMS or USSD from any phone
                  all year round, every day, at a one-off yearly infinitesimal
                  cost of about $5 for an entire year. This is the magic of
                  PlugBay at your finger tips or send 5 pictures of your
                  products and/or services to the advert section to reach
                  millions of users for 30 days for as low as $1/month. With
                  PlugBay. advertising and reaching millions of people is
                  completely demystified at a near free cost.More at
                  <span
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      marginLeft: "2px",
                    }}
                  >
                    www.plugbay.com
                  </span>
                </p>
                {/* <div className="content">
                  <Button variant="dark">Proceed to Apply</Button>
                </div> */}
              </div>
              <div className="col-lg-3">
                <div className="review-site-logo">
                  <a href="https://www.plug2jobs.com/">
                    <img
                      src={process.env.PUBLIC_URL + "/images/plugbay.png"}
                      alt="Plug2Jobs"
                    />
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-lg-3">
                <div className="testimonial-grid  layoutStyle ">
                  <span className="social-media">Job Seeker</span>
                  <p>
                    Stay up to date on the latest jobs around the country and
                    build your job profile for different employers to see .
                  </p>
                  <div className="author-info">
                    <div className="thumb">
                      <img
                        src={process.env.PUBLIC_URL + data.authorimg}
                        alt={ff}
                      />
                    </div>
                    <div className="content">
                      <Button variant="dark">Proceed to Apply</Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="testimonial-grid  layoutStyle ">
                  <span className="social-media">Agent</span>
                  <p>
                    Connect Job Seekers to employers and stay updated on latest
                    jobs available.
                  </p>
                  <div className="author-info">
                    <div className="thumb">
                      <img
                        src={process.env.PUBLIC_URL + data.authorimg}
                        alt={ff}
                      />
                    </div>
                    <div className="content">
                      <Button variant="dark">Connect Now </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="testimonial-grid  layoutStyle ">
                  <span className="social-media">Employer</span>
                  <p>
                    Create job openings and get connnected to suitable employers
                    for available roles.
                  </p>
                  <div className="author-info">
                    <div className="thumb">
                      <img
                        src={process.env.PUBLIC_URL + data.authorimg}
                        alt={ff}
                      />
                    </div>
                    <div className="content">
                      <Button variant="dark">Connect to Employees</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="section section-padding customer-review-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <SectionTitle
                  subtitle=""
                  title="Just Crowd It!"
                  description=""
                  textAlignment="heading-left"
                  textColor=""
                />
                <p className="fs-5 col-lg-8">
                  With Crowdam, you can do a product launch, enter new markets,
                  reach millions of people, get new videos and jingles out there
                  with thousands of people excitedly pushing your
                  product/advert, without even leaving the confines of your home
                  or office and all in record time. It was simply built with
                  love... find out now at
                  <span
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      marginLeft: "2px",
                    }}
                  >
                    www.crowdam.com
                  </span>
                </p>
                {/* <div className="content">
                  <Button variant="dark">Proceed to Apply</Button>
                </div> */}
              </div>
              <div className="col-lg-3">
                <div className="review-site-logo">
                  <a href="https://www.plug2jobs.com/">
                    <img
                      src={process.env.PUBLIC_URL + "/images/crowdamlogo.png"}
                      alt="Plug2Jobs"
                    />
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-lg-3">
                <div className="testimonial-grid  layoutStyle ">
                  <span className="social-media">Job Seeker</span>
                  <p>
                    Stay up to date on the latest jobs around the country and
                    build your job profile for different employers to see .
                  </p>
                  <div className="author-info">
                    <div className="thumb">
                      <img
                        src={process.env.PUBLIC_URL + data.authorimg}
                        alt={ff}
                      />
                    </div>
                    <div className="content">
                      <Button variant="dark">Proceed to Apply</Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="testimonial-grid  layoutStyle ">
                  <span className="social-media">Agent</span>
                  <p>
                    Connect Job Seekers to employers and stay updated on latest
                    jobs available.
                  </p>
                  <div className="author-info">
                    <div className="thumb">
                      <img
                        src={process.env.PUBLIC_URL + data.authorimg}
                        alt={ff}
                      />
                    </div>
                    <div className="content">
                      <Button variant="dark">Connect Now </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="testimonial-grid  layoutStyle ">
                  <span className="social-media">Employer</span>
                  <p>
                    Create job openings and get connnected to suitable employers
                    for available roles.
                  </p>
                  <div className="author-info">
                    <div className="thumb">
                      <img
                        src={process.env.PUBLIC_URL + data.authorimg}
                        alt={ff}
                      />
                    </div>
                    <div className="content">
                      <Button variant="dark">Connect to Employees</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* <ProgramOne />
        <ProgramTwo />
        <ProgramThree />
        <ProgramFour />
        <ProgramFive />
        <ProgramSix />
        <ProgramSeven />
        <ProgramEight />
        <ProgramNine />
        <ProgramTen /> */}
        {/* <ProjectOne parentClass="bg-color-light" />
        <TestimonialOne /> */}
        {/* <BrandOne /> */}
        {/* <BlogOne /> */}
        {/* <CtaLayoutOne /> */}
        <FooterOne />
      </main>
    </>
  );
};

export default Ads;
