import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaPinterestP,
  FaLinkedin,
  FaInstagram,
  FaVimeoV,
  FaDribbble,
  FaBehance,
  FaEnvelopeOpen,
  AiOutlineArrowDown,
  FaYoutubeSquare,
} from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from "../../utils";
import Logo from "../../elements/logo/Logo";
import CountrySelect from "react-bootstrap-country-select";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-country-select/dist/react-bootstrap-country-select.css";
import { LanguageList } from "react-translator-component";

const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {
  const [value, setValue] = useState(null);
  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        <div className="footer-main">
          <div className="row">
            <div className="col-xl-6 col-lg-8">
              <div className="footer-widget border-end">
                <div className="footer-newsletter">
                  <div className="header-logo">
                    <img
                      className="light-version-logo "
                      style={{ width: "180px" }}
                      src={process.env.PUBLIC_URL + "images/emplug-logo.png"}
                      alt="logo"
                    />
                  </div>
                  <p>Building a working nation!</p>
                  <form>
                    <div className="input-group">
                      <span className="mail-icon">
                        <FaEnvelopeOpen />{" "}
                      </span>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                      />
                      <button className="subscribe-btn" type="submit">
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="row">
                <div className="col-sm-4">
                  <div className="footer-widget">
                    <h6 className="widget-title">About</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={process.env.PUBLIC_URL + "/about-us"}
                          >
                            The Company
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link
                            style={{ textDecoration: "none" }}
                            to={process.env.PUBLIC_URL + "/program"}
                          >
                            Programs
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link
                            style={{ textDecoration: "none" }}
                            to={process.env.PUBLIC_URL + "/career"}
                          >
                            Career
                          </Link>
                        </li>
                        <li>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={process.env.PUBLIC_URL + "/press"}
                          >
                            Press
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="footer-widget">
                    <h6 className="widget-title">Discover</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={process.env.PUBLIC_URL + "/ecosystem"}
                          >
                            The Ecosystem
                          </Link>
                        </li>
                        <li>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={process.env.PUBLIC_URL + "/ads-channels"}
                          >
                            Ads Channels
                          </Link>
                        </li>

                        <li>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={process.env.PUBLIC_URL + "/terms-use"}
                          >
                            Events
                          </Link>
                        </li>
                        <li>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={process.env.PUBLIC_URL + "/terms-use"}
                          >
                            Blog
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="footer-widget">
                    <h6 className="widget-title">Resources</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={process.env.PUBLIC_URL + "/contact"}
                          >
                            Contact
                          </Link>
                        </li>
                        <li>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={process.env.PUBLIC_URL + "/blog-grid"}
                          >
                            Help Center
                          </Link>
                        </li>

                        <li>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={process.env.PUBLIC_URL + "/terms&policies"}
                          >
                            Terms & Policies
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-6">
                  <div className="footer-widget">
                    <h6 className="widget-title">Countries</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <CountrySelect
                            value={value}
                            onChange={setValue}
                            matchNameFromStart={false}
                            matchAbbreviations
                            flush
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="footer-top">
              <div className="footer-social-link">
                <ul className="list-unstyled">
                  <li>
                    <Link
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      to="http://Facebook.com/emplughq
"
                    >
                      <FaFacebookF />
                    </Link>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      to="http://twitter.com/emplughq"
                    >
                      <FaTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      to="https://youtube.com/@emplugmedia6142"
                    >
                      <FaYoutubeSquare />
                    </Link>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      to="https://www.linkedin.com/company/emplug/"
                    >
                      <FaLinkedin />
                    </Link>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      to="http://instagram.com/emplughq"
                    >
                      <FaInstagram />
                    </Link>
                  </li>
                  {/* <li>
                  <Link style={{ textDecoration: "none" }} to="https://vimeo.com/">
                    <FaVimeoV />
                  </Link>
                </li>
                <li>
                  <Link style={{ textDecoration: "none" }} to="https://dribbble.com/">
                    <FaDribbble />
                  </Link>
                </li>
                <li>
                  <Link style={{ textDecoration: "none" }} to="https://www.behance.net/">
                    <FaBehance />
                  </Link>
                </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-social ">
          <div className="border-line" />
          <ul className="list-unstyled social-icon">
            {/* <li>
                <a href="https://facebook.com/">
                  <FaFacebookF /> Facebook
                </a>
              </li> */}
            {/* <li>
              <a href="https://twitter.com/">
                <FaTwitter /> twitter
              </a>
            </li> */}
            {/* <li>
                <a href="https://www.linkedin.com/">
                  <FaLinkedinIn /> Linkedin
                </a>
              </li> */}
          </ul>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  Copyright © {new Date().getFullYear()}.{" "}
                  <a href="https://emplug.com/">Emplug</a>, Inc. All rights
                  reserved.
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li>
                    <LanguageList Theme="Dropdown" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
