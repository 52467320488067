import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";

const CookiePolicy = () => {
  const {pathname} =location
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const activeClick = () => {
    setActive1(true);
    setActive2(false);
    setActive3(false);
    setActive4(false);
  };
  const activeClick2 = () => {
    setActive2(true);
    setActive1(false);
    setActive3(false);
    setActive4(false);
  };
  const activeClick3 = () => {
    setActive3(true);
    setActive1(false);
    setActive2(false);
    setActive4(false);
  };
  const activeClick4 = () => {
    setActive3(false);
    setActive1(false);
    setActive2(false);
    setActive4(true);
  };
  return (
    <>
      <SEO title="Cookie Policy" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Terms & Policies" page="Cookie Policy" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4">
                <div className="list-group mb-sm-2">
                  <Link
                    to="/terms&policies"
                    onClick={activeClick}
                    className={
                      active1
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                    }
                  >
                    Terms of Service
                  </Link>
                  <Link
                    to="/privacy-policy"
                    onClick={activeClick2}
                    className={
                      active2
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                    }
                  >
                    Privacy Policies
                  </Link>
                  <Link
                    to="/cookie-policy"
                    onClick={activeClick3}
                    className={`
                        list-group-item list-group-item-action active &&  ${
                          pathname.includes("cookie") &&
                          "list-group-item list-group-item-action"
                        } `}
                  >
                    Cookie Policies
                  </Link>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="privacy-policy-content">
                  <div className="section-title">
                    <h5 className="title">Cookie Policy</h5>
                  </div>

                  <div id="cookies">
                    <p>
                      A cookie is a text-only string of information that a
                      website transfers to the cookie file of the browser of
                      your computer's hard disk so that the website can remember
                      who you are and other details. Cookies may be set by a
                      website or originate with third parties, such as
                      advertisers. A cookie itself is not designed or intended
                      to read any information from a user's computer (other than
                      the contents of the cookie). A cookie is simply an
                      identifier used by the website that originally placed it
                      on your hard drive. The actual contents of the cookie
                      information can be retrieved by the same server to
                      identify the computer and subsequently to customize,
                      track, or regulate the use of a site based on information
                      stored on the host server. We may make use of cookies on
                      our website. We use cookies to allow us to automate the
                      access and the data entry functions of our website and to
                      correlate online ordering information for purchases you
                      may make while at the website (if any), tailor our website
                      to your preferences or interests, or customize promotions
                      or marketing. In addition, cookies allow us to track use
                      of our website to determine those areas which are useful
                      or popular and those that are not thereby enabling us to
                      improve and update our website effectively. Most web
                      browsers can either alert you to the use of cookies or
                      refuse to accept cookies entirely. You have the ability to
                      accept or decline cookies by modifying the settings in
                      your browser. However, you may not be able to use all the
                      interactive features of the website if cookies are
                      disabled. We do not use flash cookies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <CtaLayoutOne /> */}
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default CookiePolicy;
