import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

const CareerSix = () => {
  return (
    <div className="section richard-section  case-study-featured-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <h3>Health and Wellness Program</h3>
                <p>
                  Emplug . invests in services and education to keep our
                  employees healthy. All Emplug Inc. businesses offer access to
                  employee assistance and mental health programs. Our offerings
                  include programs for pregnant women, cash reimbursement for
                  wellness activities, onsite fitness centers and onsite health
                  services. We also provide information to our employees about
                  health and wellness through seminars and awareness campaigns.
                </p>

                {/* <Link to="#" className="axil-btn btn-large btn-fill-primary">
                    Our Team
                  </Link> */}
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="thumbnail">
                <img
                  src={process.env.PUBLIC_URL + "/images/health.jpg"}
                  alt="thumbnail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerSix;
