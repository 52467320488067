import React from 'react'
import PropTypes from "prop-types";


const SEO = ( {title} ) => {
    return (
      <>
        <meta charSet="utf-8" />
        <title>Emplug | {title}</title>
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/emplugicon.png" />
        <meta name="robots" content="noindex, follow" />
        <meta
          name="Emplug"
          content="Emplug Ecosystem Landing Page"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </>
    );
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;