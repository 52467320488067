import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";


const Datas = [
  {
    id: 1,
    title: "Social Good as a Service",
    para: "As the global leader in Jobocracy, Emplug has implemented Social Good As a Service (S-GAS) across all her businesses of commercial nature and social good programs which have become completely interwoven, that one is no longer separate from the other which explains why we are absolutely vested in our core thematic areas of impact",
  },
  {
    id: 2,
    title:
      "The Informal Sector Impact ",
    para: "We recognize that the enormous, trillion-dollar informal sector makes up a significant portion of the world’s workforce – 61%, even though policy makers are not even sure of the subsector dynamics of this segment of society let alone formulating pragmatic and reliable policies. Given the importance of these critical players, Emplug facilitates the integration of the informal economy into the formal sector working through a multi-stakeholder partnership strategy.",
  },
  {
    id: 3,
    title: "Skill is the new norm",
    para: "Our tenacious focus on promoting a skilled-based economy in every country we operate in, is evidenced by our embracing all, especially the thriving service sector through programs and hubs that automatically provide jobs and unbiased funding for the citizenry that have high skills development and social impact components in their business ideas. ",
  },
  {
    id: 4,
    title: "The Youth and Emplug",
    para: "We advocate for entrepreneurship and for building the young towards global impact projects and to create the needed impact, we have aggressively pursued their growth through booth camps, funding-based ideation programs and centers for career development, especially in developing nations.",
  },
  {
    id: 5,
    title: "Security as a Social Enterprise",
    para: "Through Reportam, one of the leading Security Social Networks in the World, we are able to bring communities to work closely with law enforcement and several stakeholders in a most symbiotic model that has seen to crime reduction by a further 40% in countries where they have been implemented",
  },
  {
    id: 6,
    title: "Agro-Focused Impact",
    para: "Emplug’s special impact programs on Agriculture has brought some order to the often characterized as difficult relationship between farm-to-market and farm support channels to boost the development of Agriculture, especially in emerging markets.",
  },
];




const AboutThree = () => {
    return (
      <div className="section section-padding bg-color-dark mb-5 ">
        <div className="container">
          <SectionTitle
            subtitle=""
            title="Our Work... Our Humanity"
            description=""
            textAlignment="heading-left heading-light-left mb--50 w-100"
            textColor=""
          />
          <div className="case-study-counterup mb-5">
            <div className="single-counterup">
              <h2 className="count-number">
                <TrackVisibility once>
                  {({ isVisible }) => (
                    <span className="number count">
                      {isVisible ? <CountUp end="10" duration={1} /> : null}
                    </span>
                  )}
                </TrackVisibility>
                <span className="symbol">+</span>
              </h2>
              <span className="counter-title">Years on the market</span>
            </div>
            <div className="single-counterup">
              <h2 className="count-number">
                <TrackVisibility once>
                  {({ isVisible }) => (
                    <span className="number count">
                      {isVisible ? <CountUp end="200" duration={1} /> : null}
                    </span>
                  )}
                </TrackVisibility>
                <span className="symbol">+</span>
              </h2>
              <span className="counter-title">Projects delivered so far</span>
            </div>
          </div>
          <h5 style={{ color: "white" }}>
            With focus on our thematic impact areas, Emplug will deliver your
            project with the same passion and dedication for excellence and with
            partnership in mind, not in the sense of co-ownership, but for your
            success.
          </h5>
          <div className="row">
            {Datas.map((data) => (
              <div className="col-lg-4" key={data.id}>
                <div className="about-quality">
                  <h3 className="sl-number">{data.id}</h3>
                  <h5 className="title">{data.title}</h5>
                  <p>{data.para}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <ul className="list-unstyled shape-group-10">
          <li className="shape shape-1">
            <img
              src={process.env.PUBLIC_URL + "/images/others/circle-1.png"}
              alt="Circle"
            />
          </li>
          <li className="shape shape-2">
            <img
              src={process.env.PUBLIC_URL + "/images/others/line-3.png"}
              alt="Circle"
            />
          </li>
          <li className="shape shape-3">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"}
              alt="Circle"
            />
          </li>
        </ul>
      </div>
    );
}

export default AboutThree;