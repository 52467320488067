import React from "react";
import { Link } from "react-router-dom";
import FormOne from "../contact/FormOne";
import BcrumbBannerOne from "../../elements/breadcrumb/BcrumbBannerOne";
import SectionTitle from "../../elements/section-title/SectionTitle";
import { Button } from "react-bootstrap";

const BannerSix = () => {
  return (
    <>
      <div
        className="banner banner-style-5"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/images/banner/ad-banner.jpg"
          })`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-7">
              <div className="banner-content">
                <h1 className="title">Ads Channels</h1>
                <div>
                  <p className="text-white fs-5">
                    Do you wish to advertise globally on thousands of websites
                    around the world and pltforms with millions of users ? All
                    of these, without breaking the bank, definitely is Plug2Ads
                  </p>
                  <p className="text-white fs-5">
                    Or do you want to advertise all year round with a very
                    little, very infiitesimal sum, so insiignificant that it is
                    about the price of a pack of juice or a bottle of coke, for
                    an entire year of unlimited advertising. That is surely
                    PlugBay.
                  </p>
                  <p className="text-white fs-5">
                    Or maybe have a product to launch in a brand-new market and
                    the cost of running the national campaign in that country is
                    humongous. Did you know that for a fraction of that cost, you can start the national
                    campaign in minutes that will reach more than 50% of the
                    country’s population? Then you’ve probably never
                    heard of the magic of Crowdam.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-xl-5">
              {/* <div className="banner-form">
                            <div className="contact-form-box shadow-box">
                                <h3 className="title">Get a free Keystroke quote now</h3>
                                <FormOne />
                            </div>
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerSix;
